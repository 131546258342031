
export const getFormPageIds = () => {
    const elements = document.querySelectorAll('[id^="page"]');
    const ids = Array.from(elements)
      .filter(element => /^page\d+$/.test(element.id))
      .map(element => element.id);
    return ids;
}


export const goToFormNextPage =(currentPage,pageIds,setCurrentPage) =>{
    if(currentPage+1<=pageIds.length){
        hideDiv(currentPage);
        showDiv(currentPage+1);
        setCurrentPage(currentPage+1);
      }
}

export const goToFormPreviousPage = (currentPage,setCurrentPage) => {
    if(currentPage-1>=1){
        hideDiv(currentPage);
        showDiv(currentPage-1);
        setCurrentPage(currentPage-1);
      }
}

export const hideAllPagesExceptCurrentFormPage = (pageIds,currentPage) =>{
    if(pageIds.length>0){
        pageIds.forEach(id => {
            if(id.replace(/^page/, '')!=currentPage){
                const pageDiv = document.getElementById(id);
                if (pageDiv) {
                  pageDiv.style.display = "none";
                }
            }    
          });
    }
}

export const setInputFieldAndFormPageIds = (setFormIds,setPageIds,getPageIds) =>{
    const elements = document.querySelectorAll('[id^="form"]');
    const ids = Array.from(elements)
      .filter(element => /^form\d+_\d+$/.test(element.id))
      .map(element => element.id);
    setFormIds(ids);
    setPageIds(getPageIds());
}

export const loadForm= ({type}) =>{
    const Component = formComponentMap[type];
    return Component ? <Component /> : null;
}

export const setFormFieldData = (formData) =>{
    const formDataMap = JSON.parse(formData);

    for (const [fieldId, value] of Object.entries(formDataMap)) {
        const element = document.getElementById(fieldId);
        if (element) {
            // Set the value of the input field
            if(element.type==="checkbox"){
                element.checked = true;
            }else{
                element.value = value;
            }
        } else {
            console.warn(`Element with id ${fieldId} not found.`);
        }
    }

}
export const hideDiv=(pageNo)=>{
    const pageDiv = document.getElementById("page"+pageNo);
    pageDiv.style.display = "none"; 
}

export const showDiv=(pageNo)=>{
    const pageDiv = document.getElementById("page"+pageNo);
    pageDiv.style.display = ""; 
}

export const formComponentMap = {
    "(GBB 1-a-1) Gaming Licence New Application": "3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "(GBB 1-a-2) Gaming Licence Full Renewal Application": "4 Gaming_Licence_FULL_RENEWAL_GBB-1-a-2.html",
    "(GBB 1-a-3) Gaming Licence Limited Renewal Application": "5 Gaming_Licence_LIMITED_RENEWAL_GBB-1-a-3.html",
    "(GBB 1-b-1) Proxy Gaming Licence New Application": "6 Proxy_Gaming_Licence_NEW_GBB-1-b-1.html",
    "(GBB 1-b-1) Mobile Gaming Licence New Application": "7 Mobile_Gaming_Licence_NEW_GBB-1-b-1.html",
    "(GBB 1-b-1) Restricted Interactive Gaming Licence New Application":"8 Restricted_Interactive_Gaming_Licence_NEW_GBB-1-b-1.html",
    "(GBB 7-a-1) Junket Operator Licence New Application":"9 Junket_Operator_Licence_NEW_GBB-7-a-1.html",
    "(GBB 7-a-2) Junket Operator Licence Renewal Application":"10 Junket_Operator_Licence_RENEWAL_GBB-7-a-2.html",
    "(GBB 2-a-1) Gaming House Operator Licence New Application":"11 Gaming_House_Operator_Licence_NEW_GBB-2-a-1.html",
    "(GBB 2-a-2) Gaming House Operator Licence Renewal Application":"12 Gaming_House_Operator_Licence_RENEWAL_GBB-2-a-2.html",
    "(GBB 2-b-1) Gaming House Premises Licence New Application":"13 Gaming_House_Premises_Licence_NEW_GBB-2-b-1.html",
    "(GBB 2-b-2) Gaming House Premises Licence Renewal Application":"14 Gaming_House_Premises_Licence_RENEWAL_GBB-2-b-2.html",
    "(GBB 2-c-1) Gaming House Agent Licence New Application":"15 Gaming_House_Agent_Licence_NEW_GBB-2-c-1.html",
    "(GBB 2-c-2) Gaming House Agent Licence Renewal Application":"16 Gaming_House_Agent_Licence_RENEWAL_GBB-2-c-2.html",
    "(GBB 3-a-1) Supplier Licence New Application":"17 Supplier_Licence_NEW_GBB-3-a-1.html",
    "(GBB 3-a-2) Supplier Licence Renewal Application":"18 Supplier_Licence_RENEWAL_GBB-3-a-2.html",
    "(GBB 4-a-2) Key Employee Licence Full Renewal Application":"20 Key_Employee_Licence_FULL_RENEWAL_GBB-4-a-2.html",
    "(GBB 4-a-3) Key Employee Licence Limited Renewal Application":"21 Key_Employee_Licence_LIMITED_GBB-4-a-3.html",
    "(GBB 4-b-1) Gaming Employee Licence Multi-Jurisdictional Application":"22 Gaming_Employee_Licence_NEW_PHD_Multi-Jurisdictional_3-0_GBB-4-b-1.html",
    "(GBB 4-b-2) Gaming Employee Licence Full Renewal Application":"23 Gaming_Employee_Licence_FULL_RENEWAL_GBB-4-b-2.html",
    "(GBB 4-b-3) Gaming Employee Licence Limited Renewal Application":"24 Gaming_Employee_Licence_LIMITED_RENEWAL_GBB-4-b-3.html",
    "(GBB 4-c-1) Temporary Employee Licence New Application":"25 Temporary_Employee_Licence_NEW_GBB-4-c-1.html",
    "(GBB 4-c-2) Authorisation For Examination And Release Of Information And Indemnification Standard Application":"26 Release_Authorisation_Indemnification_STANDARD_GBB-4-c-2.html",
    "(GBB 6-a-1) Registration As An Independent Testing Laboratory New Application":"27 Registration_Independent_Testing_Laboratory_NEW_GBB-6-a-1.html",
    "(GBB 6-a-2) Registration As An Independent Testing Laboratory Renewal Application":"28 Registration_Independent_Testing_Laboratory_RENEWAL_GBB-6-a-2.html",
    "(GBB 5-b-2) Certificate Of Suitability As A Third Party Contractor To The Holder Of An Operator Licence New Application":"29 Certificate_Suitability_Third_Party_Contractor_NEW_GBB-5-b-2.html",
    "(GBB 5-c-1) Certificate Of Suitability To Hold/Procure A Financial Interest In A Licence Holder (BED) New Application":"30 Certificate_Suitability_BED_Hold_Procure_Financial_Interest_in_Licensee_NEW_GBB-5-c-1.html",
    "(GBB 5-d-1) Certificate Of Suitability For Corporate Bodies Having Significant Control Over The Operations Of An Independent Testing Laboratory New Application":"32 Certificate_Suitability_Significant_Control_over_Testing_Laboratory_NEW_GBB-5-d-1.html",
    "(GBB 4-a-1) Key Employee Licence Multi-Jurisdictional Application":"19 Key_Employee_Licence_NEW_PHD_Multi-Jurisdictional_GBB-4-a-1.html",
    "(GBB 5-c-2) Certificate Of Suitability To Hold/Procure A Financial Interest In A Licence Holder (BED) Renewal Application":"31 Certificate_Suitability_BED_Hold_Procure_Financial_Interest_in_Licensee_RENEWAL_GBB-5-c-2.html",
    "(GBB 5-e-1) Certificate Of Suitability To Hold/Procure A Financial Interest In A Licence Holder (PHD) New Application":"33 Certificate_Suitability_PHD_Hold_Procure_Financial_Interest_in_Licensee_NEW_GBB-5-e-1.html",
    "Independent Junket Agent Registration Form":"35-Independent-Junket-Agent-Registration-Form.html",
    "Monthly Gaming Tax Return Form (Gaming House Operator)":"1-monthly_gaming_tax_return_form_gb.html"
}