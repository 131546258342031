// reducers/feedbackSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showError, showSuccess } from "../../helpers/toast";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Replace this with your API's base URL

  // Other default configurations (headers, timeout, etc.) can be set here if needed
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  message: "",
};

export const sendFeedback = createAsyncThunk("sendFeedback", async (data) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };
    const response = await API.post("/feedbacks", data.feedbackData, config);
    data.resetForm();
    showSuccess("Feedback sent successfully");
    return response.data;
  } catch (error) {
    showError("Feedback failed to send");
    throw new Error("Failed to load feedbacks");
  }
});

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.loading = true;
        state.message = "";
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchError = null;
        state.message = "Feedback submitted successfully";
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        state.loading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
        state.message = "";
      });
  },
});

export default feedbackSlice.reducer;
