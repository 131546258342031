import React, { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateSignUpData } from "../../../store/reducers/signUpSlice";
import * as Yup from "yup";
import logo from "../../../assets/media/logo.png";
import booksAnimation from "../../../assets/media/books-animation.gif";
import GBInput from "../../input";

const SignUp2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signUpUser = useSelector((state) => state.signUp);
  const user = useSelector((state) => state.auth.user);

  console.log(user, "signup-data");

  const initialValues = {
    phone: user?.phone || "",
    companyRegistrationNo: user?.company?.company_id || "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string().required("Phone Number is required"),
    // .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
    companyRegistrationNo: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log({ values });
      dispatch(
        updateSignUpData({
          signupcode: signUpUser.signupcode,
          password: signUpUser.password,
          full_name: signUpUser.full_name,
          phone: values.phone,
          companyRegistrationNo: values.companyRegistrationNo,
        })
      );
      if (signUpUser?.signupcode) {
        navigate("/signup-step3");
      }
    },
  });
  const { values, handleChange, handleSubmit, errors } = formik;

  const fields = [
    {
      name: "phone",
      label: "Phone Number",
      type: "tel",
      placeholder: "(702) 555-0122",
      value: values.phone,
      onChange: handleChange,
    },
    ...(user.user_role === "company_admin"
      ? [
          {
            name: "companyRegistrationNo",
            label: "Company Registration No.",
            type: "text",
            placeholder: "Enter Company Registration No.",
            value: values.companyRegistrationNo,
            readOnly: user?.company?.company_id ? true : false,
            onChange: handleChange,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (!signUpUser?.access_token) {
      // navigate('/signup-step1');
    }
  }, [navigate, signUpUser?.access_token]);

  return (
    <div className="App">
      <div className="container">
        <div className="landingWrapper">
          <div className="landingRow">
            <div className="landingCol">
              <div className="fieldsWrapper">
                <img src={logo} className="logo" alt="logo" />
                <h2>Signup With Us</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </p>
                <form className="gb-login-form" onSubmit={handleSubmit}>
                  {fields.map((field, index) => {
                    return (
                      <Fragment key={index}>
                        <GBInput
                          name={field.name}
                          type={field.type}
                          label={field.label}
                          value={field.value}
                          placeholder={field.placeholder}
                          setValue={handleChange}
                          readOnly={field.readOnly}
                        />
                        {errors[`${field.name}`] && (
                          <div className="error-message error-message-wrap">
                            {errors[`${field.name}`]}
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                  <div className="gb-form-footer">
                    <button type="submit" className="form-submit">
                      Continue
                    </button>
                  </div>
                </form>
                <div className="gb-step-list">
                  <span className="current"></span>
                  <span className="current"></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="landingCol">
              <div className="imageWrapper">
                <div className="loginBox">
                  <p>
                    Already have account? <Link to={"/"}>Sign In</Link>
                  </p>
                </div>
                <img src={booksAnimation} className="booksAnimation" alt="" />
                <h3>
                  <span>Paper Less</span>
                  <strong>Documentations</strong>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp2;
