// reducers/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showError } from "../../helpers/toast";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Replace this with your API's base URL

  // Other default configurations (headers, timeout, etc.) can be set here if needed
});

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const registerUser = createAsyncThunk(
  "/auth/register",
  async (userData) => {
    try {
      const response = await API.post("/auth/register", userData);
      return response.data;
    } catch (error) {
      throw new Error("Failed to register user");
    }
  }
);

export const loginUser = createAsyncThunk(
  "/auth/login",
  async (credentials) => {
    try {
      const response = await API.post("/auth/login", credentials);
      return response.data;
    } catch (error) {
      throw new Error("Failed to log in");
    }
  }
);

export const logoutUser = createAsyncThunk("/auth/logout", async () => {
  try {
    await API.delete("/auth/logout");
  } catch (error) {
    throw new Error("Failed to log out");
  }
});

export const registerUserData = createAsyncThunk(
  "/users/signup-data",
  async (signupcpde) => {
    try {
      const response = await API.post("/users/signup-data", signupcpde);
      return response.data;
    } catch (error) {
      throw new Error("Failed to get signup data");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUserData.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // alert()
        showError("Invalid Credentials");
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authSlice.reducer;
