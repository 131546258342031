import React, { useEffect, useState } from 'react'
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";

import { useDispatch, useSelector } from 'react-redux';
import { fetchApplications } from '../../../store/reducers/applicationSlice';
import Navbar from '../../navbar';
import Header from '../../header';
import Pagination from '../dashboard/appPagination';
import { handleFilesDownload } from '../../../store/reducers/fileSlice';

const AppApprovedApp = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;

    const applications = useSelector((state) => state.applications);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
    const [filteredApplication, setFilteredApplication] = useState([]);
    const [downloadingIds, setDownloadingIds] = useState([]); // State for tracking downloads

    useEffect(() => {
        dispatch(fetchApplications({ accessToken: authToken }));
    }, [authToken, dispatch]);



    useEffect(() => {
        if (applications.data?.length > 0 && getallUserdata?.length > 0) {
            const mergedData = applications.data.map((appItem) => {
                const userItem = getallUserdata.find((userData) => userData.id === appItem.user_id);
                if (userItem) {
                    return {
                        ...appItem,
                        applicationId: appItem.id,
                        ...userItem,
                        userId: userItem.id,
                    };
                }
                return { ...appItem, applicationId: appItem.id };
            });

            const filteredData = mergedData.filter((item) =>
                item.company_id === user.user.company_id &&
                item.state === "approved" &&
                Object.values(item).some((value) =>
                    value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );

            setFilteredApplication(filteredData);
        }
    }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
        return Math.floor(filteredApplication.length / 10) + 1;
    };

    const handleDownload = async (item) => {
        const { applicationId, userId } = item;

        // Add applicationId to downloadingIds
        setDownloadingIds((prev) => [...prev, applicationId]);

        try {
            await dispatch(handleFilesDownload({ userId, applicationId, authToken })); // Ensure this is an async action
        } catch (error) {
            console.error("Download failed", error);
        } finally {
            // Remove applicationId from downloadingIds
            setDownloadingIds((prev) => prev.filter((id) => id !== applicationId));
        }
    };

    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>Approved Applications</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj4'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Submitted Date
                            </div>
                            <div className='boardCol w-165'>
                                Registered Date
                            </div>
                            <div className='boardCol w-165'>
                            </div>
                            <div className='boardCol w-110'>

                            </div>
                        </div>
                        {filteredApplication.sort((a, b) => b.applicationId - a.applicationId).slice((currentPage - 1) * 10, currentPage * 10).map((item, index) => (
                            <div className='boardTrow' key={item.id}>
                                <div className='boardCol w-85'>
                                    {index + 1}
                                </div>
                                <div className='boardCol w-165'>
                                    {`GB-${item.applicationId}`}
                                </div>
                                <div className='boardCol w-adj4'>
                                    <div className='boardTitle'>
                                        <strong>{item.name}</strong>
                                        <span>{item.form}</span>
                                    </div>
                                </div>
                                <div className='boardCol w-165'>
                                    {item?.updated_at.slice(0, 10)}
                                </div>
                                <div className='boardCol w-165'>
                                    {item?.updated_at.slice(0, 10)}
                                </div>
                                <div className='boardCol w-165'>
                                    <div className='boardDownload'>

                                    </div>
                                </div>
                                <div className='boardCol w-110'>
                                    <div className='boardActions'>
                                        {downloadingIds.includes(item.applicationId) ? (
                                            <div className="loader"></div> // Your loader component or spinner here
                                        ) : (
                                            <img onClick={() => handleDownload(item)} src={iconFileDownload} alt='Download' />
                                        )}                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
        </div>
    );
};

export default AppApprovedApp;