import React, { Fragment, useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
const GBInput = ({
  label,
  type,
  placeholder,
  value,
  setValue,
  id,
  name,
  iconSrc,
  className = "",
  readOnly = null,
  error = null,
  disabled = false,
}) => {
  const [inputType, setInputType] = useState(type);
  const toggleInputType = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const style = {
    position: "absolute",
    bottom: "10px",
    right: "12px",
    width: "20px",
    height: "20px",
  };

  return (
    <div className="gb-field-wrap">
      <label>{label}</label>
      {type === "tel" ? (
        <PhoneInput
          //   className={`form-class ${className}`}
          placeholder={placeholder}
          value={value}
          onChange={setValue}
          id={id}
          name={name}
          readOnly={readOnly}
          country={"us"}
          inputProps={{
            name: name,
            value: value,
            onChange: setValue,
            required: true,
            autoFocus: true,
          }}
          disabled={disabled}
        />
      ) : (
        <input
          className={`form-class ${className}`}
          // type={(type === 'password' && isVisiblePassword) ? 'text' : 'password'}
          // type={(type === "text" && !isVisiblePassword) ? 'text' : 'password'}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={setValue}
          autoComplete={"false"}
          id={id}
          name={name}
          readOnly={readOnly}
          disabled={disabled}
        />
      )}
      {
        <>
          {type === "password" && (
            <Fragment>
              {inputType === "password" ? (
                <AiOutlineEyeInvisible
                  onClick={toggleInputType}
                  style={style}
                />
              ) : (
                <AiOutlineEye style={style} onClick={toggleInputType} />
              )}
            </Fragment>
          )}
          <>
            {iconSrc && inputType !== "password" && (
              <img src={iconSrc} className="iconField" alt="" />
            )}
          </>
        </>
      }
      {/* {error ? <div>{error}</div> : null} */}
    </div>
  );
};

export default GBInput;
