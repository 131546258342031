import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  updatePassword,
  updateUser,
} from "../../../store/reducers/userSlice";
import Navbar from "../../navbar";
import Header from "../../header";
import {
  getbyidCompany,
  updateCompany,
} from "../../../store/reducers/companiesSlice";
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";
import PhoneInput from "react-phone-input-2";
import { type } from "@testing-library/user-event/dist/type";
import GBInput from "../../input";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const getUserData = useSelector((state) => state.usersdata.getuserdata);
  const userLoading = useSelector((state) => state.usersdata.loading);
  const updateLoader = useSelector((state) => state.usersdata.updateLoader);
  const passwordLoader = useSelector((state) => state.usersdata.passwordLoader);
  const company = useSelector((state) => state.companies.companies);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [nationalidnumber, setNationalidnumber] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [employees, setEmployees] = useState("");
  const [companyphone, setCompanyphone] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagedata, setImagedata] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  useEffect(() => {
    dispatch(
      getUser({
        userId: user.user.id,
        userData: { accessToken: user.access_token },
      })
    );
    dispatch(
      getbyidCompany({
        companyId: user.user.company_id,
        companyData: { accessToken: user.access_token },
      })
    );
  }, [user, imagedata]);

  useEffect(() => {
    if (getUserData && !userLoading) {
      setPhone(getUserData.phone);
    }
  }, [getUserData, userLoading]);

  useEffect(() => {
    if (company) {
      setCompanyphone(company.phone_no);
    }
  }, [company]);

  if (!getUserData) {
    return <div>Loading...</div>;
  }

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
      setImagedata(reader.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const removeImage = () => {
    setSelectedImage(null);
  };

  const postdata = () => {
    const userId = user.user.id;
    const companyid = user.user.company_id;
    const accessToken = user.access_token;
    const userData = {
      email: email || getUserData.email,
      phone: phone || getUserData.phone,
      full_name: fullname || getUserData.full_name,
      national_id_number: nationalidnumber || getUserData.national_id_number,
      ...(password && { password }),
      image: selectedImage || getUserData.image,
      accessToken: user.access_token,
    };

    const companyData = {
      company_name: companyname || company.company_name,
      no_of_employee: employees || company.no_of_employee,
      phone_no: companyphone || company.phone_no,
    };
    dispatch(updateUser({ userId, userData }));
    dispatch(updateCompany({ companyid, companyData, accessToken }));
    dispatch(
      getUser({
        userId: user.user.id,
        userData: { accessToken: user.access_token },
      })
    );
    dispatch(
      getbyidCompany({
        companyId: user.user.company_id,
        companyData: { accessToken: user.access_token },
      })
    );
  };

  const handlePasswordChange = () => {
    const userId = user.user.id;
    const userData = {
      old_password: oldPassword,
      password: newPassword,
      confirm_password: confirmPassword,
    };

    const onSuccess = () => {
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    };

    dispatch(
      updatePassword({ userId, userData, token: user.access_token, onSuccess })
    );
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="content-wrapper">
          <div className="profHeader">
            <div className="profHeadLeft">
              <h2>My Profile</h2>
            </div>
            <div className="profHeadRight">
              <div className="btngroup">
                <Link className="buttonSecondary" to={"/"}>
                  Delete Account
                </Link>
                <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                  className="buttonPrimary"
                  onClick={postdata}
                >
                  {updateLoader && (
                    <div
                      class="text-xs spinner-border text-light"
                      style={{ width: "1rem", height: "1rem" }}
                      role="status"
                    ></div>
                  )}
                  Save Changes
                </Link>
              </div>
            </div>
          </div>

          <div className="divUpload">
            <div className="uploadLeft">
              <img
                className="avatar"
                src={imagedata || getUserData.image || userImagePlaceholder}
                alt=""
              />
              <p>
                Upload your profile picture. <strong>Max size 2MB</strong>
              </p>
            </div>
            <div className="uploadRight">
              <div className="btngroup">
                <button className="buttonSecondary" onClick={removeImage}>
                  Remove
                </button>
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <label htmlFor="imageUpload" className="buttonPrimary">
                  Browse
                </label>
              </div>
            </div>
          </div>

          <div
            className="accordion accordion-flush profileAccord"
            id="accordionProfile"
          >
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personalnformation"
                >
                  Personal Information
                </button>
              </h2>
              <div
                id="personalnformation"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionProfile"
              >
                <div className="accordion-body">
                  <div className="empAdd">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <div className="fieldWrap">
                          <label>Full Name</label>
                          <input
                            onChange={(e) => setFullname(e.target.value)}
                            value={fullname || getUserData.full_name}
                            type="text"
                            placeholder="Marvin McKinney"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="fieldWrap">
                          <label>National Identity Number</label>
                          <input
                            onChange={(e) =>
                              setNationalidnumber(e.target.value)
                            }
                            value={
                              nationalidnumber || getUserData.national_id_number
                            }
                            type="text"
                            placeholder="Marvin McKinney"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="fieldWrap">
                          <label>Email Address</label>
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email || getUserData.email}
                            type="text"
                            placeholder="Marvin McKinney"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="fieldWrap">
                          <label>Phone No.</label>
                          <PhoneInput
                            placeholder={"(702) 555-0122"}
                            onChange={(e) => setPhone(e?.target?.value)}
                            country={"us"}
                            autoFormat={true}
                            inputProps={{
                              name: "phone",
                              value: phone,
                              onChange: (e) => setPhone(e.target.value),
                            }}
                          />
                          {/* <input
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone || getUserData.phone}
                            type="text"
                            placeholder="(702) 555-0122"
                          /> */}
                        </div>
                      </div>
                      {/* <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap noLabel'>
                                                    <button onClick={postdata} >Update</button>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                >
                  Company Information
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionProfile"
              >
                <div className="accordion-body">
                  <div className="empAdd">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <div className="fieldWrap">
                          <label>Company Name</label>
                          <input
                            onChange={(e) => setCompanyname(e.target.value)}
                            value={companyname || company.company_name}
                            type="text"
                            placeholder="Marvin McKinney"
                            readOnly={user.user_role !== "company_admin"}
                            disabled={user.user_role !== "company_admin"}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="fieldWrap">
                          <label>No Of Employee</label>
                          <input
                            onChange={(e) => setEmployees(e.target.value)}
                            value={employees || company.no_of_employee}
                            type="text"
                            placeholder="Marvin McKinney"
                            readOnly={user.user_role !== "company_admin"}
                            disabled={user.user_role !== "company_admin"}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-4">
                        <div className="fieldWrap">
                          <label>Phone No.</label>
                          <PhoneInput
                            placeholder={"(702) 555-0122"}
                            onChange={(e) => setCompanyphone(e?.target?.value)}
                            country={"us"}
                            autoFormat={true}
                            inputProps={{
                              name: "phone",
                              value: companyphone,
                              onChange: (e) => setCompanyphone(e.target.value),
                              readOnly: user.user_role !== "company_admin",
                              disabled: user.user_role !== "company_admin",
                            }}
                            readOnly={user.user_role !== "company_admin"}
                            disabled={user.user_role !== "company_admin"}
                          />
                          {/* <input
                            onChange={(e) => setCompanyphone(e.target.value)}
                            value={companyphone || company.phone_no}
                            type="text"
                            placeholder="(702) 555-0122"
                          /> */}
                        </div>
                      </div>
                      {/* <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap noLabel'>
                                                    <button type='submit'>Update</button>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#changePassword"
                >
                  Change Password
                </button>
              </h2>
              <div
                id="changePassword"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionProfile"
              >
                <div className="accordion-body">
                  <div className="empAdd">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <div
                          className="fieldWrap "
                          style={{ position: "relative" }}
                        >
                          <label>Old Password</label>
                          <GBInput
                            setValue={(e) => setOldPassword(e.target.value)}
                            type={"password"}
                            placeholder="Current password"
                            value={oldPassword}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div
                          className="fieldWrap"
                          style={{ position: "relative" }}
                        >
                          <label>New Password</label>
                          <GBInput
                            setValue={(e) => {
                              const password = e.target.value;
                              setNewPassword(password);
                              const alphanumericSpecialRegex =
                                /^(?=.*[a-zA-Z])(?=.*[0-9])[^\s]{8,}$/;
                              if (!alphanumericSpecialRegex.test(password)) {
                                setNewPasswordError(
                                  "Password must be at least 8 characters long and contain both letters and numbers."
                                );
                              } else {
                                setNewPasswordError("");
                              }
                            }}
                            type={"password"}
                            placeholder="New password"
                            className={newPasswordError ? "error-input" : ""}
                            value={newPassword}
                          />
                        </div>
                        {newPasswordError && (
                          <div className="error-message">
                            {newPasswordError}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-lg-4">
                        <div
                          className="fieldWrap"
                          style={{ position: "relative" }}
                        >
                          <label>Confirm Password</label>
                          <GBInput
                            setValue={(e) => {
                              const password = e.target.value;
                              setConfirmPassword(password);
                              if (password !== newPassword) {
                                setConfirmPasswordError(
                                  "Passwords do not match."
                                );
                              } else {
                                setConfirmPasswordError("");
                              }
                            }}
                            type={"password"}
                            placeholder="Confirm password"
                            className={
                              confirmPasswordError ? "error-input" : ""
                            }
                            value={confirmPassword}
                          />
                        </div>
                        {confirmPasswordError && (
                          <div className="error-message">
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        disabled={
                          oldPassword === "" ||
                          newPassword === "" ||
                          confirmPassword === "" ||
                          newPasswordError ||
                          confirmPasswordError ||
                          passwordLoader
                        }
                        className="form-submit"
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                        onClick={handlePasswordChange}
                      >
                        {passwordLoader && (
                          <div
                            class="text-xs spinner-border text-light"
                            style={{ width: "1rem", height: "1rem" }}
                            role="status"
                          ></div>
                        )}
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row profileNotify">
            <div className="col-12 col-lg-6">Get All Notifications On</div>
            <div className="col-12 col-lg-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
