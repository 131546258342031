
import React,{useState} from "react";
import {hideDiv,showDiv} from '../../../utility/utility';
const DescripanciesBox = ({descrepancies,setCurrentPage,currentPage}) => {
  const [currentFieldSelected,setCurrentFieldSelected] = useState("null");
   const inputBoxClicked =(id) =>{
    if(currentFieldSelected != id){
        if(currentFieldSelected!="null"){
            const currentFieldSelectedElement = document.getElementById(currentFieldSelected.replace("dis_",""));
            currentFieldSelectedElement.style.backgroundColor ='';
            
        }
        setCurrentFieldSelected(id);
        id = id.replace("dis_","");
        const temp = id.split('_');
        const pageId = temp[temp.length - 1];
        if(currentPage!=pageId){
            hideDiv(currentPage)
            showDiv(pageId)
            setCurrentPage(pageId);
        }
        const element =document.getElementById(id);
        if(element){
            element.style.backgroundColor = '#ff0000'; // Change background color to red
        }
        

    }else{
        id = id.replace("dis_","");
        const element =document.getElementById(id);
        if(element){
            element.style.backgroundColor = ''; // Change background color to red
        }
        setCurrentFieldSelected("null"); 
    }
       
   }
      

    return (
        <div className="discontainer">
            <div className='title'>Discrepancies</div>
            <div className='disform'>
                <div className='boardHeadRight'>
                    <div className='boardFilter'>
                    {Object.entries(descrepancies).map(([id, data]) => (
    <div className="form-check form-check-inline" key={id}>
        <input
            id= {id}
            className="form-check-input"
            type="checkbox"
            checked={data.check}
             // Reflect the checked state
             onClick={()=>inputBoxClicked(id)}
            readOnly
        />
        <input
            id={id}
            type="text"
            placeholder="add discrepancy"
            value={data.value} // Reflect the value state
            className="form-check-label"
            onClick={()=>inputBoxClicked(id)}
            readOnly
        />
    </div>
))}
                    </div>
                </div>
                <label className="mainlabel mt">Other</label>
                <textarea />
            </div>
            {/* <div className="buttonGroup">
                <a className="AppFormButton" onClick={handleAddDiscrepancy}>Update Discrepancies</a>
            </div> */}
        </div>
    );
};


export default  DescripanciesBox;