import React, { useEffect, useState } from 'react';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import classes from './style.module.css'
import { postApplication, safePdf } from '../../../store/reducers/applicationSlice';
import { createNotification } from '../../../store/reducers/notificationSlice';
import { loadForm } from '../../../utility/utility';
import FormHeader from '../forms/formHeader/formHeader';
import AddAttachment from '../applications/attachments';
import FormLoader from '../forms/formLoader';
import { useNavigate } from "react-router-dom";

const AppForm = () => {

  const [formIds, setFormIds] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [pageIds, setPageIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const accessToken = useSelector((state) => state.auth.user.access_token);
  const categories = useSelector((state) => state.categories);
  const user = useSelector((state) => state.auth.user);
  const { applyingFormData } = useSelector((state) => state.counter)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [signatureIds, setSignatureIds] = useState([]);
  const [imageData, setImageData] = useState('');
  const dispatch = useDispatch();
  const [showAttachments, setShowAttachments] = useState(false);
  const navigate = useNavigate();




  useEffect(() => {
    if (pageIds.length > 0) {
      pageIds.forEach(id => {
        if (id.replace(/^page/, '') != currentPage) {
          const pageDiv = document.getElementById(id);
          if (pageDiv) {
            pageDiv.style.display = "none";
          }
        }
      });
    }
  }, [pageIds])

  useEffect(() => {
    if (signatureIds.length > 0) {
      signatureIds.forEach(id => {
        const imageInput = document.getElementById(id);
        if (imageInput && imageInput.type === 'image') {
          // Directly set the src of the image input
          let originalWidth = window.getComputedStyle(imageInput).width.replace("px", "")
          let originalTop = window.getComputedStyle(imageInput).top.replace("px", "")
          let height = (originalWidth * 114) / 179
          imageInput.style.height = height + "px";
          imageInput.style.top = (originalTop - height + 20) + "px";
          imageInput.style.objectFit = 'contain'; // Optional: Ensures the image covers the entire area
          imageInput.style.pointerEvents = 'none'; // Makes the input non-clickable
        }
      });
    }

  }, [signatureIds])





  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (formIds.length > 0) {
      attachEventHandlers();
    }
  }, [formIds.length]);
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setInputFields(prevInputFields => ({
      ...prevInputFields,
      [id]: value,
    }));

  };
  const handleCheckBoxChange = (event) => {
    const { id, checked, value } = event.target;

    setInputFields(prevInputFields => {
      // If the checkbox is checked, add it to inputFields
      if (checked) {
        return {
          ...prevInputFields,
          [id]: value,
        };
      } else {
        // If the checkbox is unchecked, remove it from inputFields
        const { [id]: removedValue, ...remainingFields } = prevInputFields;
        return remainingFields;
      }
    })
  };

  const attachEventHandlers = () => {
    let tempSignatureIds = []
    formIds.forEach(id => {
      const inputField = document.getElementById(id);
      if (inputField) {
        if (inputField.type === 'image') {
          tempSignatureIds.push(id);

        } else if (inputField.type === 'checkbox') {
          inputField.addEventListener('change', handleCheckBoxChange)
        }
        else {
          inputField.addEventListener('change', handleInputChange);
        }
      }

    });
    setSignatureIds(tempSignatureIds);
  };
  async function handleSubmitWithLoading(e) {
    setIsLoading(true);
    let htmlForm = document.getElementById("DocumentPDF").outerHTML;
    htmlForm = removeDisplayNoneByIds(htmlForm, pageIds);
    const data = await dispatch(postApplication({
      form: applyingFormData?.name,
      json_data: inputFields,
      name: applyingFormData?.name,
      category: applyingFormData?.category,
      user_id: user.user.id,
      token: accessToken,
      files: uploadedFiles,
      state: "in_process"
    }))

    dispatch(safePdf({
      htmlForm,
      user_id: user.user.id,
      id: data.payload.id,
      token: accessToken
    }))
    setIsLoading(false);
    navigate('/in-process-applications');
  };

  async function saveToDrafts(e) {
    setIsLoading(true);
    let htmlForm = document.getElementById("DocumentPDF").outerHTML;
    htmlForm = removeDisplayNoneByIds(htmlForm, pageIds);
    const data = await dispatch(postApplication({
      form: applyingFormData?.name,
      json_data: inputFields,
      name: applyingFormData?.name,
      category: applyingFormData?.category,
      user_id: user.user.id,
      token: accessToken,
      files: uploadedFiles,
      state: e
    }))

    dispatch(safePdf({
      htmlForm,
      user_id: user.user.id,
      id: data.payload.id,
      token: accessToken
    }))
    setIsLoading(false);
    dispatch(createNotification({
      message: 'Application Saved to drafts',
      user_id: user.user.id,
      accessToken: accessToken,
      is_admin: true
    }))
    navigate('/draft');
  };
  function removeDisplayNoneByIds(htmlContent, ids) {
    // Parse the HTML string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Loop through the array of IDs
    ids.forEach(id => {
      const element = doc.getElementById(id);
      if (element && element.style.display === 'none') {
        element.style.display = '';  // Remove the 'display: none' style
      }
    });
    // JSON.parse(formData);
    Object.entries(inputFields).forEach(([id, value]) => {
      // Get the input element by ID
      const element = doc.getElementById(id);

      // Check if the element exists and is an input field
      if (element) {
        const tagName = element.tagName.toLowerCase();
        if (tagName === 'input' || tagName === 'textarea') {
            // Use setAttribute to set the value attribute
            element.setAttribute('value', value);
    
            // For textarea, also update the text content directly
            if (tagName === 'textarea') {
                element.textContent = value;
            }
        }
    }
      if (element.type === 'checkbox') {
        // Set the checked state of the checkbox
        element.setAttribute('checked', true);
      }
    });

    const modifiedHtmlContent = new XMLSerializer().serializeToString(doc);
    return modifiedHtmlContent;
  };
  const getPageIds = () => {
    const elements = document.querySelectorAll('[id^="page"]');
    const ids = Array.from(elements)
      .filter(element => /^page\d+$/.test(element.id))
      .map(element => element.id);
    return ids;
  };
  const previousPage = () => {
    if (currentPage == pageIds.length + 1) {
      setShowAttachments(false);
      showDiv(currentPage - 1);
      setCurrentPage(currentPage - 1)
    } else {
      if (currentPage - 1 >= 1) {
        hideDiv(currentPage);
        showDiv(currentPage - 1);
        setCurrentPage(currentPage - 1);
      }
    }
  }

  const nextPage = () => {
    if (currentPage + 1 <= pageIds.length) {
      hideDiv(currentPage);
      showDiv(currentPage + 1);
      setCurrentPage(currentPage + 1);
    } else if (currentPage + 1 <= pageIds.length + 1) {
      hideDiv(currentPage);
      setCurrentPage(currentPage + 1);
      setShowAttachments(true);
    }
  }
  const hideDiv = (pageNo) => {
    const pageDiv = document.getElementById("page" + pageNo);
    pageDiv.style.display = "none";
  }

  const showDiv = (pageNo) => {
    const pageDiv = document.getElementById("page" + pageNo);
    pageDiv.style.display = "";
  }

  const handlePageChange = (number) => {
    if (number > 0 && number <= pageIds.length + 1) {
      if (number == pageIds.length + 1) {
        hideDiv(currentPage);
        setCurrentPage(number);
        setShowAttachments(true);
      } else {
        if (currentPage == pageIds.length + 1) {
          setShowAttachments(false)
          showDiv(number)
          setCurrentPage(number)
        } else {
          hideDiv(currentPage);
          showDiv(number);
          setCurrentPage(number);
        }
      }
    }
  }

  return (
    <div>
      <Navbar />
      <Header />
      <div className='wrapper'>
        <div className='boxAppBoard boxWhite'>
          <div className="stickyFormHeader">
            <FormHeader
              applicationName={applyingFormData.name}
              nextPage={nextPage}
              previousPage={previousPage}
              currentPage={currentPage}
              totalPages={pageIds.length + 1}
              handleSubmitWithLoading={handleSubmitWithLoading}
              saveToDrafts={saveToDrafts}
              handlePageChange={handlePageChange}
            />
          </div>

          <div className={classes['board-content']}>
            {/* {loadForm({ type: applyingFormData.name })} */}
            <FormLoader applicationName={applyingFormData.name} setFormIds={setFormIds} setPageIds={setPageIds} />
          </div>
          {showAttachments && <AddAttachment signatureIds={signatureIds}  uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />}
        </div>
      </div>
    </div>
  )
}
export default AppForm