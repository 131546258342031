import React, { useEffect, useState } from 'react'
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import Navbar from '../../navbar';
import Header from '../../header';
import Pagination from '../dashboard/appPagination';
import { useDispatch, useSelector } from 'react-redux';
import { fetchV2Applications } from '../../../store/reducers/applicationSlice';
import { handleFilesDownload } from '../../../store/reducers/fileSlice';
import { AiOutlineEye } from 'react-icons/ai'

import { useNavigate } from 'react-router-dom';
const AppRenewals = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;


    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredApplication, setFilteredApplication] = useState([]);
    const [downloadingIds, setDownloadingIds] = useState([]); // State for tracking downloads

    useEffect(() => {
        const fetchData = async () => {
            // Define query parameters
            const queryParams = {
                accessToken: authToken,
                state: 'approved',
                dueInDays: 15,
                sortOrder: 'DESC',
                sortField: 'id',
                userId: user.id
            };

            // Fetch applications with query parameters
            const response = await fetchV2Applications(queryParams);
            setFilteredApplication(response?.applications || []);
        };

        fetchData();
    }, [authToken]);




    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
        return Math.floor(filteredApplication.length / 10) + 1;
    };

    const handleDownload = async (item) => {
        const { id, userId } = item;
        const applicationId = id
        // Add applicationId to downloadingIds
        setDownloadingIds((prev) => [...prev, applicationId]);

        try {
            await dispatch(handleFilesDownload({ userId, applicationId, authToken })); // Ensure this is an async action
        } catch (error) {
            console.error("Download failed", error);
        } finally {
            // Remove applicationId from downloadingIds
            setDownloadingIds((prev) => prev.filter((id) => id !== applicationId));
        }
    };


    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>Renewals</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj4'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Due Date
                            </div>
                            <div className='boardCol w-165'>
                                Last Registered
                            </div>
                            <div className='boardCol w-165'>
                            </div>
                            <div className='boardCol w-110'>

                            </div>
                        </div>
                        {filteredApplication.sort((a, b) => b.id - a.id).slice((currentPage - 1) * 10, currentPage * 10).map((item, index) => (
                            <div className='boardTrow' key={item.id} >
                                <div className='boardCol w-85'>
                                    {index + 1}
                                </div>
                                <div className='boardCol w-165'>
                                    {`GB-${item.id}`}
                                </div>
                                <div className='boardCol w-adj4'>
                                    <div className='boardTitle'>
                                        <strong>{item.name}</strong>
                                        <span>{item.categoryName
                                        }</span>
                                    </div>
                                </div>
                                <div className='boardCol w-165'>
                                    {item?.updated_at.slice(0, 10)}
                                </div>
                                <div className='boardCol w-165'>
                                    {item?.updated_at.slice(0, 10)}
                                </div>
                                <div className='boardCol w-165'>
                                    <div className='boardDownload'>

                                    </div>
                                </div>
                                <div className='boardCol w-110'>
                                    <div className='boardActions'>
                                        {downloadingIds.includes(item.id) ? (
                                            <div className="loader"></div> // Your loader component or spinner here
                                        ) : (
                                            <img onClick={() => handleDownload(item)} src={iconFileDownload} alt='Download' />
                                        )}
                                        <AiOutlineEye onClick={() => navigate(`/app-renewal/${item.id}`)} style={{ cursor: 'pointer' }} />

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
        </div>
    )
}
export default AppRenewals