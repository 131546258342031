import React from "react";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import Avatar from "../../../assets/media/avatar-profile.png";
import { Link } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendFeedback } from "../../../store/reducers/feedbackSlice";
import { createNotification } from "../../../store/reducers/notificationSlice";

const Feedback = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.feedback.loading);
  const authToken = user.access_token;
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Email is required"),
      message: Yup.string().required("Password is required"),
    }),
    onSubmit: (formValues) => {
      dispatch(
        sendFeedback({
          accessToken: authToken,
          feedbackData: { ...formValues, email: user?.user?.email },
          resetForm: resetForm,
        })
      );
      dispatch(
        createNotification({
          message: "Submit New Feedback",
          user_id: user.user.id,
          accessToken: authToken,
          is_admin: true,
        })
      );
    },
  });

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="content-wrapper">
          <div className="feebackBox">
            <h2>Feedback</h2>
            <p className="description">
              Give your feedback to improve our services
            </p>
            <form onSubmit={handleSubmit}>
              <div className="fieldWrap">
                <label>Subject</label>
                <input
                  type="text"
                  placeholder="Marvin McKinney"
                  value={values["subject"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete={"false"}
                  id={"subject"}
                  name={"subject"}
                />
                {touched["subject"] && errors["subject"] && (
                  <div className="error-message">{errors["subject"]}</div>
                )}
              </div>
              <div className="fieldWrap">
                <label>Message</label>
                <textarea
                  placeholder="Write message"
                  rows="5"
                  value={values["message"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete={"false"}
                  id={"message"}
                  name={"message"}
                ></textarea>
                {touched["message"] && errors["message"] && (
                  <div className="error-message">{errors["message"]}</div>
                )}
              </div>

              <div className="fieldWrap footerWrap">
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                  type="submit"
                >
                  {loading && (
                    <div
                      class="text-xs spinner-border text-light"
                      style={{ width: "1rem", height: "1rem" }}
                      role="status"
                    ></div>
                  )}
                  Send Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Feedback;
